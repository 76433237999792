/* eslint-disable filenames/match-regex */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';

/**
 * CTA Button with variants
 *   primaryAction = true, button background is buttonprimary (blue)
 *   primaryAction = false, button background is transparent
 *   className = subscribe, button background is subscribebutton (pink), round corners only on the right
 *   className = payment-button, button background is #eb008c
 *   className = with-header-background, button background is a gradient (blue)
 *
 * Flags
 *   primaryAction = true | false  => with background | transparent
 *   gradientButton = true | false => with Gradient background | inherit
 *
 */
const CTAButton = ({
  type,
  children,
  onClick,
  style,
  className,
  disabled,
  ariaLabel,
  primaryAction,
  secondaryButton,
  tertiaryButton,
  gradientButton,
  formId,
  dataqa,
  isToggled,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <button
        aria-label={ariaLabel}
        className={classNames(
          'general-button',
          {
            'gradient-button': gradientButton,
            'primary-button': primaryAction,
            'secondary-button': secondaryButton,
            'tertiary-button': tertiaryButton,
            toggled: isToggled,
          },
          className,
        )}
        data-qa={dataqa}
        disabled={disabled}
        form={formId}
        onClick={onClick}
        style={style}
        type={type || 'button'}
      >
        {children}
      </button>

      <style jsx>{`
        .general-button {
          border-radius: 6px;
          display: inline-block;
          font-weight: 700;
          margin-bottom: 4px;
          min-height: 42px;
          padding: 4px 16px;
          text-transform: ${theme.text.cta.button};
          white-space: nowrap;
        }
        @media (max-width: ${theme.breakpoints.xlDown}) {
          .general-button {
            padding: 4px 8px;
          }
        }
        .general-button.with-margin {
          margin-right: 20px;
        }

        .savings-button {
          border-radius: 6px;
          display: inline-block;
          font-weight: 700;
          margin-bottom: 4px;
          min-height: 42px;
          padding: 4px 16px;
          white-space: nowrap;
          background-color: #eb008c;
        }
        .gifts-banner-button {
          background-color: #2400eb;
          border-color: #2400eb;
          color: white;
          padding: 0 15px;
          border-radius: 25px;
          font-weight: bold;
          font-size: 18px;
          text-transform: uppercase;
        }
        .credit-refund-button {
          background-color: ${theme.colors.dealviewbutton};
          border-color: ${theme.colors.dealviewbutton};
          color: white;
          border-radius: 25px;
          font-weight: bold;
          font-size: 11px;
          text-transform: none;
        }
        .customer-service-button {
          background-color: #25d366;
          color: white;
          border-radius: 25px;
          font-weight: bold;
          font-size: 10px;
          border: none;
          text-transform: none;
        }
        .cancel-refund-button {
          background-color: #2400eb;
          border-color: #2400eb;
          color: white;
          border-radius: 25px;
          font-weight: bold;
          font-size: 10px;
          border: none;
          width: 100%;
        }
        .cancel-button {
          border: 1px solid grey;
          background-color: #ffff;
          width: 73%;
        }
        .refund-button {
          width: 73%;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .gifts-banner-button {
            font-size: 25px;
          }
        }
        .general-button[disabled] {
          opacity: 0.4;
          pointer-events: none;
        }
        .general-button.full-width {
          width: 100%;
        }
        .primary-button {
          background-color: ${theme.colors.buttonprimary};
          color: ${theme.colors.buttontext};
          border: none;
          border-bottom: 3px solid ${theme.colors.buttonprimaryhover};
        }

        .primary-button.toggled,
        .primary-button:hover {
          background-color: ${theme.colors.buttonprimaryhover};
          border-bottom: 3px solid #1e7192;
        }
        .primary-button:active {
          border-bottom: 2px solid #1e7192;
        }

        .secondary-button {
          background-color: ${theme.colors.buttonsecondary};
          color: ${theme.colors.buttontext};
          border: none;
          border-bottom: 3px solid ${theme.colors.buttonsecondaryhover};
        }
        .secondary-button:hover {
          background-color: ${theme.colors.buttonsecondaryhover};
          border-bottom: 3px solid #520031;
        }
        .secondary-button:active {
          border-bottom: 2px solid #520031;
        }

        .tertiary-button {
          background-color: transparent;
          color: ${theme.colors.buttontertiary};
          border: 1px solid ${theme.colors.buttontertiary};
        }
        .tertiary-button:hover,
        .tertiary-button:active {
          background-color: ${theme.colors.buttontertiaryhover};
          color: ${theme.colors.buttontext};
          border: 1px solid ${theme.colors.buttontertiary};
        }

        .black-button {
          background: #1a1a1a;
          border: none;
          border-bottom: 3px solid #000000;
        }
        .black-button:hover,
        .black-button:active {
          background: #1a1a1a;
          border-bottom: 3px solid #000000;
        }

        .gold-button {
          background: #eec954;
          border: none;
          border-bottom: 3px solid #c89c14;
          color: #5d4909;
          text-shadow: 1px 1px 1px #ffffff;
        }
        .gold-button:hover,
        .gold-button:active {
          border: none;
          background: #e9ba26;
          color: #5d4909;
          border-bottom: 3px solid #997810;
        }

        .green-button {
          background: #abdb78;
          border: none;
          border-bottom: 3px solid #92d050;
        }
        .green-button.toggled,
        .green-button:hover,
        .green-button:active {
          background: #92d050;
          border-bottom: 3px solid #79bb32;
        }

        .grey-button {
          background: #c7c7c7;
          border: none;
          border-bottom: 3px solid #b3b3b3;
          color: #000;
        }
        .dropdown-button {
          background-color: #fff;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: none;
          border-radius: 20px;
          margin: 0;
          padding: 0px 10px;
        }
        .dropdown-button.toggled {
          border-radius: 20px 20px 0 0;
        }
        .grey-button.toggled,
        .grey-button:hover,
        .grey-button:active {
          background: #b3b3b3;
          border-bottom: 3px solid #949494;
        }

        .klarna-button {
          align-items: center;
          background-color: #171717;
          border-color: #ffffff;
          border-radius: 0px;
          border-style: solid;
          border-width: 1px;
          display: flex;
          flex-direction: row;
          flex-shrink: 0;
          height: 50px;
          justify-content: center;
          min-height: 0px;
          min-width: 0px;
          padding-left: 24px;
          padding-right: 24px;
          position: relative;
          transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s,
            color 0.2s ease 0s;
          width: 100%;
          z-index: 0;
        }
        .klarna-button.toggled,
        .klarna-button:hover,
        .klarna-button:active {
          background-color: #333333;
          border-color: #ffffff;
        }
        .buy-with-vip-button,
        .buy-with-vip-button:hover,
        .buy-with-vip-button:active {
          background: #0000eb;
          border-bottom: 2px solid #0000eb;
          border-radius: 5px;
          color: #ffffff;
        }
        .apple-pay-btn {
          font-family: -apple-system;
          font-size: 22px;
          font-weight: 500;
          text-transform: none;
        }

        .subscribe.primary-button {
          background: ${theme.colors.subscribebutton};
          font-size: 14px;
          border-radius: 0px 6px 6px 0px;
          border: 0;
          color: #fff;
          cursor: pointer;
          height: 40px;
          margin: 0;
          padding: 0 8px;
          right: 0;
          text-transform: uppercase;
          font-weight: 100;
          min-width: 80px;
        }

        .payment-button {
          background-color: ${theme.colors.checkoutmodulebg};
          border: 2px solid black;
          border-radius: 36px;
          color: ${theme.colors.bodytext};
          padding: 0px;
          margin: 10px;
          width: 15%;
        }

        @media (max-width: ${theme.breakpoints.xlDown}) {
          .gifts-banner-button {
            font-size: 25px;
          }
          .payment-button {
            border: 1px solid black;
            border-radius: 5px;
            height: 100%;
            margin: 15px 4px;
            padding: 3px;
            width: 20%;
          }
        }

        .payment-button--isActive {
          background-color: ${theme.colors.paymentbuttonbg};
          border: 3px solid ${theme.colors.inputfocuscolor};
        }

        .payment-button--mobile--isActive {
          background-color: ${theme.colors.paymentbuttonbg};
          border: 2px solid ${theme.colors.inputfocuscolor};
        }

        @media (max-width: 980px) {
          .subscribe.primary-button {
            min-width: 70px;
          }
        }

        .wallet.primary-button {
          margin-top: 25px;
          background-color: ${theme.colors.primary};
          border-bottom: 3px solid ${theme.colors.primarydark};
        }
        .wallet.primary-button:hover {
          background-color: ${theme.colors.primarydark};
          border-bottom: 3px solid ${theme.colors.buttonbordercolor};
        }

        .with-header-background.primary-button {
          background: linear-gradient(
            180deg,
            ${theme.colors.linealgradient1} 0,
            ${theme.colors.linealgradient2}
          );
          color: #fff;
          width: fit-content;
        }
        .with-header-background.primary-button {
          font-size: 1.8rem;
        }

        // Gradient Button styles
        .gradient-button:hover {
          border-bottom: none;
        }
        .gradient-button {
          border-bottom: none;
          text-transform: ${theme.text.error404};
          font-size: 22px;
          font-weight: 100;
          background: linear-gradient(
            180deg,
            ${theme.colors.linealgradient1} 0,
            ${theme.colors.linealgradient2}
          );
          color: #fff;
          width: fit-content;
        }
        .large-border-radius {
          border-radius: 60px;
          padding: 0 20px;
        }
        @media (min-width: ${theme.breakpoints.lgUp}) {
          .credit-refund-button {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  );
};

CTAButton.propTypes = {
  className: PropTypes.string,
  dataqa: PropTypes.string,
  disabled: PropTypes.bool,
  formId: PropTypes.string,
  gradientButton: PropTypes.bool,
  onClick: PropTypes.func,
  primaryAction: PropTypes.bool,
  secondaryButton: PropTypes.bool,
  style: PropTypes.object,
  tertiaryButton: PropTypes.bool,
  type: PropTypes.string,
};

CTAButton.defaultProps = {
  dataqa: null,
  gradientButton: false,
  primaryAction: true,
};

export default CTAButton;
